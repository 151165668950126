<template>
  <div class="container">
    <!-- 卡片 -->
    <el-card class="box-card">
      <!-- 搜索 -->
      <div class="share-content layout">
        <el-input v-model="query.orderNum" placeholder="请输入订单号" style="width:300px;"></el-input>
        <el-date-picker @change="changeData" v-model="shareDate" type="datetimerange" range-separator="至"
                        start-placeholder="开始日期" end-placeholder="结束日期"></el-date-picker>
        <el-select v-model="query.status" placeholder="请选择订单状态">
          <el-option v-for="item in statusOptions" :key="item.value" :label="item.label"
                     :value="item.value"></el-option>
        </el-select>
        <el-button type="success" @click="getData">搜索</el-button>
        <el-button type="warning" @click="handlerReset">重置</el-button>
      </div>
      <!-- 表格 -->
      <el-table :data="tableData" border stripe style="width: 100%" v-loading="loading">
        <el-table-column align="center" label="用户ID" prop="userId" width="100"></el-table-column>
        <el-table-column align="center" label="订单号" prop="orderNum"></el-table-column>
        <el-table-column align="center" width="120" label="商品数量" prop="productCount"></el-table-column>
        <el-table-column align="center" width="120" label="商品总价" prop="productTotalPrice"></el-table-column>
        <el-table-column align="center" width="130" label="实际支付金额" prop="payMoney"></el-table-column>
        <el-table-column align="center" label="创建时间" prop="createTime"></el-table-column>
        <el-table-column align="center" label="付款时间" prop="payTime"></el-table-column>
        <el-table-column align="center" width="100" label="订单状态">
          <template slot-scope="scope">
            <el-tag :type="colorStatus[scope.row.status]" disable-transitions>{{ status[scope.row.status] }}</el-tag>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" width="200">
          <template slot-scope="scope">
            <el-button v-if="scope.row.status == 1" type="primary" icon="el-icon-thumb" size="mini"
                       @click="handlerDeliver(scope.row.orderNum)">发货
            </el-button>
            <el-button type="primary" icon="el-icon-thumb" size="mini" v-else style=" opacity: 0;">发货</el-button>
            <el-button type="success" icon="el-icon-thumb" size="mini"
                       @click="$router.push({path:`/orderInfo?orderNum=${scope.row.orderNum}`})">详情
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" :current-page="query.current"
                     :page-sizes="[5, 10, 20, 30]" :page-size="query.size"
                     layout="total, sizes, prev, pager, next, jumper" :total="total">
      </el-pagination>
    </el-card>
    <!-- 发货弹框 -->
    <el-dialog top="15%" title="发货" :visible.sync="deliverShow" width="30%" :before-close="handleClose">
      <el-form :model="deliverQuery" ref="form" label-width="120px" class="demo-ruleForm" :rules="rules">
        <el-form-item label="快递名称:" prop="expressName">
          <el-input @focus="clearValidate('expressName')" v-model="deliverQuery.expressName" placeholder="请输入快递名称"
                    autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="快递单号:" prop="expressNum">
          <el-input @focus="clearValidate('expressNum')" v-model="deliverQuery.expressNum" placeholder="请输入快递单号"
                    autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="confingDeliver">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {orderList, orderDeliver} from "../../apis/order.js";
import {status, colorStatus, typeStatus, statusOptions} from './index.js'
import {deliver} from '../../utils/rules'

export default {
  data() {
    return {
      query: {
        current: 1,
        size: 10,
        orderNum: '',
        status: '',
        endTime: '',
        startTime: ''
      },
      tableData: [],
      loading: false,
      total: 100,
      status: status,
      colorStatus: colorStatus,
      typeStatus: typeStatus,
      statusOptions: statusOptions,
      shareDate: '',
      deliverQuery: {
        expressName: '',
        expressNum: '',
        orderNum: ''
      },
      deliverShow: false,
      rules: deliver.rules
    };
  },
  created() {
    this.getData();
  },
  methods: {
    // 发货
    handlerDeliver(orderNum) {
      this.deliverQuery.orderNum = orderNum
      this.deliverShow = true
    },
    handleClose() {
      this.deliverShow = false
      this.clearCen()
    },
    confingDeliver() {
      this.$refs.form.validate(async val => {
        if (!val) return
        await orderDeliver(this.deliverQuery)
        this.$message({message: '发货成功', type: "success"})
        this.deliverShow = false
        this.getData()
        this.clearCen()
      })
    },
    // 初始化状态
    clearCen() {
      this.$refs.form.resetFields(); // 重置表单
      Object.keys(this.deliverQuery).map((key) => (this.deliverQuery[key] = ""));
    },

    async getData() {
      this.loading = true;
      const res = await orderList(this.query);
      const {records, total, current, size} = res.data;
      this.tableData = records;
      this.total = total;
      this.query.current = current;
      this.query.size = size;
      setTimeout(() => {
        this.loading = false;
      }, 50);
    },
    handleSizeChange(size) {
      this.query.size = size;
      this.getData();
    },
    handleCurrentChange(page) {
      this.query.current = page;
      this.getData();
    },
    changeData(val) {
      this.query.startTime = this.$moment(val[0]).format(
          "YYYY-MM-DD hh:mm:ss"
      );
      this.query.endTime = this.$moment(val[1]).format(
          "YYYY-MM-DD hh:mm:ss"
      );
    },
    handlerReset() {
      this.query = {
        ...this.query,
        orderNum: '',
        status: '',
        endTime: '',
        startTime: ''
      }
      this.shareDate = ''
      this.getData()
    },
    // 获取焦点对表单重新校验
    clearValidate(prop) {
      this.$refs.form.clearValidate(prop);
    },
  },
};
</script>
<style lang="less" scoped>
.detail-list {
  align-items: flex-start;
}

.el-form-item {
  flex: 1;
}

.share-content {
  justify-content: flex-start;

  .el-input {
    width: 200px;
  }

  .el-range-editor {
    margin: 0 20px;
  }

  .el-select {
    margin-right: 20px;
  }
}
</style>