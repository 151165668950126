// 订单管理
import axios from 'axios'
import QS from 'qs'

// 订单列表
export const orderList = params => {
    return axios.post('/api/orders/ordersPage', QS.stringify(params)).then(res => res.data)
}
// 订单详情
export const OrdersItme = params => {
    return axios.post('/api/orders/ordersItme', QS.stringify(params)).then(res => res.data)
}
// 根据订单获取订单信息
export const getOrderNumber = params => {
    return axios.post('/api/orders/getOrdersId', QS.stringify(params)).then(res => res.data)
}
// 发货
export const orderDeliver = params => {
    return axios.post('/api/orders/orderDeliver', QS.stringify(params)).then(res => res.data)
}