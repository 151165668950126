export const status = {
    "-1": "已取消",
    0: "待支付",
    1: "待发货",
    2: "待收货",
    3: "售后中",
    4: "已完成",
    5: "交易失败",
}

export const colorStatus = {
    "-1": "info",
    0: "danger",
    1: "success",
    2: "warning",
    3: "",
    4: "success",
    5: "danger",
}

export const typeStatus = {
    0: "线上课程",
    1: "线下课程",
    2: "实体商品",
    3: "直播打赏",
    4: "开通 VIP"
}

export const typeStatusColor = {
    0: "",
    1: "success",
    2: "warning",
    3: "info",
    4: "success"
}

export const statusOptions = [
    {
        value: '-1',
        label: '已取消'
    }, {
        value: '0',
        label: ' 待支付'
    }, {
        value: '1',
        label: '待发货'
    }, {
        value: '2',
        label: '待收货'
    }, {
        value: '3',
        label: '售后中'
    }, {
        value: '4',
        label: '已完成'
    }, {
        value: '5',
        label: '交易失败'
    }
]